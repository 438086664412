import { getName, getLanguage, getRole } from "../helpers/auth";
import WelcomeGanache from "../Layouts/WelcomeGanache.js";
import Avatar from "react-avatar";
import I18n from "../Config/I18n";
import { Button, Card, Col, Row } from "react-bootstrap";
import { GiSecretBook, GiKnifeFork } from "react-icons/gi";
import { Url } from "../Config/url";
import React, {useEffect, useState} from "react";
import PaymentAPI from "../backend/payment";
import PopupPayment from "./payment/PopupPayment";
import PopupDemo from "./payment/PopupDemo";
import PopupDemoExpired from "./payment/PopupDemoExpired";
const l = getLanguage();


const cards = [
  {
    id: "1",
    name: `${I18n.t("DashboardUser.myRecipes", { locale: l })}`,
    category: "recipes",
    path: Url.folder,
    icon: <GiSecretBook size="3em" />,
    textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
  },
  {
    id: "2",
    name: `${I18n.t("DashboardUser.myIngredients", { locale: l })}`,
    category: "Ingredients",
    path: Url.ingredients,
    icon: <GiKnifeFork size="3em" />,
    textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
  },
  {
    id: "3",
    name: `${I18n.t("DashboardUser.myProfile", { locale: l })}`,
    category: "Profile",
    path: Url.profile,
    icon: (
      <Avatar
        className="text-white"
        color={Avatar.getRandomColor("sitebase", ["rgb(230, 126, 34)", "rgba(30,85,98,1)", "#2E2E2E"])}
        name={getName()}
        round
        size={48}
      />
    ),
    textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
  },
];

function DashboardUser() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasMollieId, setHasMollieId] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDemoUpgraded, setIsDemoUpgraded] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showDemoExpiredPopup, setShowDemoExpiredPopup] = useState(false);



  useEffect(() => {
    checkSubscription();
    checkHasMollieId();
    checkIsDemoUpgraded();
    checkDemoStatus();  // Combined logic to handle demo status
  }, []);

  useEffect(() => {
    if (hasMollieId && !isSubscribed) {
      handleOpenPopupPayment();
    }
  }, [hasMollieId, isSubscribed]);

  const checkSubscription = async () => {
    const sub = await PaymentAPI.hasSubscription();
    console.log("sub", sub);
    if (!sub.error && sub.data.subscription) {
      setIsSubscribed(true);
    }
  };

  const handleOpenPopupPayment = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopupPayment = () => {
    setIsPopupOpen(false);
  };

  const checkHasMollieId = async () => {
    const hasMollie = await PaymentAPI.hasMollieId();
    if (!hasMollie.error && hasMollie.data.mollieid) {
      setHasMollieId(true);
    }
  };

  const checkIsDemoUpgraded = async () => {
    const demoup = await PaymentAPI.isDemoUpgraded();
    if (!demoup.error && demoup.data.demoupgraded) {
      setIsDemoUpgraded(true);
    }
  };

  const checkDemoStatus = async () => {
    const role = getRole();

    if (role === "demo") {
      const exp = await PaymentAPI.isDemoExpired();
      if (!exp.error && exp.data.demoexpired === true) {
        setShowDemoExpiredPopup(true);  // Demo is expired
      } else {
        setShowDemoPopup(true);  // Demo is not expired, show demo popup
      }
    }
  };


  const handleClosePopupDemo = () => {
    setShowDemoPopup(false);
  };

  const handleClosePopupDemoExpired = () => {
    setShowDemoExpiredPopup(false);
  };


  return (
    <div>


      {/*{hasMollieId && !isSubscribed && !isDemoUpgraded && ((*/}

      {/*    <PopupPayment*/}
      {/*        open={isPopupOpen}*/}
      {/*        handleClosePopupPayment={handleClosePopupPayment}*/}
      {/*    />*/}

      {/*))}*/}

      {showDemoExpiredPopup && (
          <PopupDemoExpired
              open={showDemoExpiredPopup}
              handleClosePopupDemoExpired={handleClosePopupDemoExpired}
          />
      )}

      {!showDemoExpiredPopup && showDemoPopup && (
          <PopupDemo
              open={showDemoPopup}
              handleClosePopupDemo={handleClosePopupDemo}
          />
      )}

      <div className="flex my-5">
        <div className="flex-1 mx-5 my-auto">
          <h1 className="text-3xl font-bold">
            {I18n.t("DashboardUser.hello", { locale: l })} {getName()}
          </h1>
        </div>
        <div className="flex-1">
          <WelcomeGanache />
        </div>
      </div>
      <Row className="mx-2 justify-content-center">
        {cards.map((card, index) => {
          return (
            <Col key={index} className="col-auto mb-3">
              <Card className="card-intro">
                <Card.Header className="flex flex-col items-center justify-center gap-y-6">
                  {card.icon}
                  {card.name}
                </Card.Header>
                <Card.Body className="mx-auto">
                  <Button href={card.path} variant="primary">
                    {card.textButton}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default DashboardUser;
